import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Header from "../components/Sections/Privacy/Header"
import Content from "../components/Sections/Privacy/Content"
import ogImage from "../assets/images/og_image.png"

const query = graphql`
  {
    wordpressPage(template: { eq: "page-cookies.php" }) {
      title
      content
      acf {
        seo_title
        seo_description
      }
    }
  }
`

const CookiesPage = () => {
  const data = useStaticQuery(query)

  const { title, content } = data.wordpressPage
  const { seo_title, seo_description } = data.wordpressPage.acf

  return (
    <Layout>
      <SEO image={ogImage} title={seo_title} description={seo_description} />
      <Header title={title} />
      <Content content={content} />
    </Layout>
  )
}

export default CookiesPage
